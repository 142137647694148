import { useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three-stdlib'
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module.js' 

export function Prism({ onRayOver, onRayOut, onRayMove, ...props }) {
  const mod = useLoader(GLTFLoader, '/gltf/t-cat.glb',loader => {
    // Use MeshoptDecoder to decode compressed data
    loader.setMeshoptDecoder(MeshoptDecoder)
  })
  const { nodes } = mod
  return (
    <group {...props}>
      {/* A low-res, invisible representation of the prism that gets hit by the raycaster */}
      <mesh visible={false} scale={1.9} rotation={[Math.PI / 2, Math.PI, 0]} onRayOver={onRayOver} onRayOut={onRayOut} onRayMove={onRayMove}>
        <cylinderGeometry args={[1, 1, 1, 3, 1]} />
      </mesh>
      {/* The visible hi-res prism */}
      {/* <mesh position={[0, 0, 0.6]} renderOrder={10} scale={2} dispose={null} geometry={nodes?.Cone?.geometry}>
        <meshPhysicalMaterial clearcoat={1} clearcoatRoughness={0} transmission={1} thickness={0.9} roughness={0} toneMapped={false} />
      </mesh> */}
      <primitive object={mod.scene} scale={0.6} />
    </group>
  )
}
